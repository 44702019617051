import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './custom.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import firebase from './config/fbConfig'
import 'firebase/functions'
import ReduxToastr from 'react-redux-toastr'
import Geocode from "react-geocode";
import moment from 'moment';
import 'moment/locale/it';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer,
    composeEnhancers(applyMiddleware(thunk.withExtraArgument({ getFirebase }))),
);

const rrfConfig = {
    userProfile: 'users',
    enableClaims: true,
    useFirestoreForProfile: true
}


const rrfProps = {
    firebase: firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance: createFirestoreInstance
}

const toastrProps = {
    timeOut: 4000,
    newestOnTop: false,
    preventDuplicates: true,
    position: "top-right",
    transitionIn: "fadeIn",
    transitionOut: "fadeOut",
}

Geocode.setApiKey("AIzaSyA7-StQ6t1T2XETxrQhv2MzwsxpepWckas");

Geocode.setLanguage("it");

Geocode.setRegion("it");

moment.locale("it");

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <App />
            <ReduxToastr {...toastrProps} />
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root'));

serviceWorker.unregister();

