import React from 'react'
import { Component } from 'react';

class ManualAddressForm extends Component {
    state = {
        addressLine: "",
        zipCode: "",
        city: "",
        province: "",
        typingTimeout: 0,
    }

    handleChange = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            [e.target.id]: e.target.value,
            typingTimeout: setTimeout(this.stoppedTyping, 700)
        });
    }

    stoppedTyping = () => {
        let { addressLine, zipCode, city, province } = this.state;
        if (addressLine !== "" && zipCode !== "" && city !== "" && province !== "") {
            let address = addressLine + " " + zipCode + " " + city + " " + province;
            this.props.onAddressSelect(address);
        }
    }


    render() {
        return (
            <div>
                <div className="d-flex flex-wrap">
                    <div className="form-group flex-fill mr-2">
                        <label htmlFor="addressLine" className="required">Indirizzo</label>
                        <input className="form-control" type="text" id="addressLine" value={this.state.addressLine} placeholder="Via e numero civico" required onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city" className="required">Città</label>
                        <input className="form-control" type="text" id="city" value={this.state.city} required onChange={this.handleChange} />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <div className="form-group mr-2">
                        <label htmlFor="province" className="required">Provincia</label>
                        <input className="form-control" maxLength="2" type="text" id="province" value={this.state.province} required onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zipCode" className="required">Codice postale</label>
                        <input className="form-control" type="text" id="zipCode" value={this.state.zipCode} required onChange={this.handleChange} />
                    </div>
                </div>

                <div>
                    <span className="manual" onClick={this.props.toggleManual}> Inserisci l&apos;indirizzo in base al nome dell&apos;attività</span>
                </div>

            </div>
        );
    }
}

export default ManualAddressForm;