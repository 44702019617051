import { toastr } from "react-redux-toastr";
import { resizeImage } from "../../utility/FileHelper";
import RequestStates from "../../utility/RequestStates";
import moment from "moment";

export const createDriverRequest = (data) => {
    return async (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'DRIVER_REQUEST_LOADING' });
        const firebase = getFirebase();

        const firestore = firebase.firestore();
        const storage = firebase.storage();
        const auth = firebase.auth();

        const documentRef = firestore.collection('driver_requests').doc();

        const storageRef = storage.ref().child('driver_requests/' + documentRef.id);
        try {
            let uid = (await auth.createUserWithEmailAndPassword(data.email, data.password)).user.uid;
            let IDCardFrontImage = await resizeImage(data.IDCardFrontFile, 1000, 1000, 100);
            let IDCardBackImage = await resizeImage(data.IDCardBackFile, 1000, 1000, 100);
            let fullSizeImage = await resizeImage(data.imageFile, 1000, 1000, 100);
            let thumbnailImage = await resizeImage(data.imageFile, 500, 500, 90);

            await storageRef.child("id_card_front_image").put(IDCardFrontImage);
            await storageRef.child("id_card_back_image").put(IDCardBackImage);
            await storageRef.child("full_size_image").put(fullSizeImage);
            await storageRef.child("thumbnail_image").put(thumbnailImage);

            let IDCardFrontImageUrl = await storageRef.child("id_card_front_image").getDownloadURL();
            let IDCardBackImageUrl = await storageRef.child("id_card_back_image").getDownloadURL();
            let fullSizeImageUrl = await storageRef.child("full_size_image").getDownloadURL();
            let thumbnailImageUrl = await storageRef.child("thumbnail_image").getDownloadURL();

            await documentRef.set({
                name: data.name,
                email: data.email,
                uid: uid,
                fiscalCode: data.fiscalCode,
                description: data.description,
                imageUrl: fullSizeImageUrl,
                thumbnailUrl: thumbnailImageUrl,
                IDCardFrontUrl: IDCardFrontImageUrl,
                IDCardBackUrl: IDCardBackImageUrl,
                phoneNumber: data.phoneNumber,
                meansOfTransport: data.meansOfTransport,
                areaId: data.area.id,
                iban: data.iban,
                state: RequestStates.PENDING,
                timestamp: firebase.firestore.Timestamp.fromMillis(moment().valueOf()),
                reference: documentRef,
            });

            dispatch({
                type: 'DRIVER_REQUEST_SUCCESS'
            });
        } catch (err) {
            if (err.code === 'auth/email-already-in-use') {
                toastr.error('Email già in uso!');
                dispatch({ type: 'DRIVER_REQUEST_ERROR', step: 2 })
            } else if (err.code === 'auth/invalid-email') {
                toastr.error("Formato dell'email non valido!");
                dispatch({ type: 'DRIVER_REQUEST_ERROR', step: 2 })
            } else if (err.code === 'auth/weak-password') {
                toastr.error("Password troppo corta!");
                dispatch({ type: 'DRIVER_REQUEST_ERROR', step: 2 })
            } else {
                toastr.error(`Errore inaspettato!`);
                dispatch({ type: 'DRIVER_REQUEST_ERROR', step: 0 })
            }
            console.log(err);
        }
    }
};