import React from 'react';
import './styles/MainScreen.css';
import { Link } from 'react-router-dom';
import Container from '../common/container/Container';

export default function MainScreen() {
  return (
    <div className="main-screen">
      <Container>
        <h3>Collabora con noi</h3>
        <h5>Per cominciare dicci cosa sei</h5>
        <div className="row mt-3">
          <div className="col-sm-6 col-12 link-container container1">
            <Link to="/driver" className="link">Sono un fattorino</Link>
          </div>
          <div className="col-sm-6 col-12 link-container container2">
            <Link to="/supplier" className="link">Sono un fornitore</Link>
          </div>
        </div>
      </Container>
    </div>
  );
}