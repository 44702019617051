const initState = {
  requestLoading: false,
  requestSuccess: false,
  requestError: false,
  stepWithError: null,
}

const supplierFormReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SUPPLIER_REQUEST_LOADING':
      return {
        ...state,
        requestLoading: true,
        requestSuccess: false,
        requestError: false,
        stepWithError: null,
      };

    case 'SUPPLIER_REQUEST_SUCCESS':
      return {
        ...state,
        requestLoading: false,
        requestSuccess: true,
        requestError: false,
        stepWithError: null,
      };

    case 'SUPPLIER_REQUEST_ERROR':
      return {
        ...state,
        requestLoading: false,
        requestSuccess: false,
        requestError: true,
        stepWithError: action.step
      };
    default:
      return state
  }
};

export default supplierFormReducer;