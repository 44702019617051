import React from 'react'
import { Component } from 'react';
import './styles/Step4Form.css'
import { withRouter } from 'react-router-dom';
import Pdf from "../../../../file/Contratto fornitori.pdf"

class Step4Screen extends Component {
    handleChange = (e) => {
        this.props.onChange(e.target.id, e.target.value);
    }


    render() {
        return (
            <div className="supplier-step-3">
                <h3>Modulo di richiesta - Step 4/4</h3>
                <h5 className="mt-4">Conto in cui ricevere i pagamenti</h5>
                <div className="form-group">
                    <label htmlFor="iban" className="required">IBAN</label>
                    <input className="form-control" type="text" id="iban" value={this.props.data.iban} required onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="ipa" className="required">Codice univoco per la fatturazione elettronica (CUU o IPA)</label>
                    <input className="form-control" type="text" id="ipa" value={this.props.data.ipa} required onChange={this.handleChange} />
                </div>
                <h5 className="mt-4">Dati di accesso alla piattaforma</h5>
                <div className="form-group">
                    <label htmlFor="email" className="required">Email</label>
                    <input className="form-control" type="email" id="email" value={this.props.data.email} required onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="adminPhoneNumber">Numero di cellulare</label>
                    <input className="form-control" id="adminPhoneNumber" value={this.props.data.adminPhoneNumber} onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="password" className="required">Password</label>
                    <input className="form-control" minLength="8" type="password" id="password" value={this.props.data.password} required onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="passwordConfirm" className="required">Conferma password</label>
                    <input className="form-control" minLength="8" type="password" id="passwordConfirm" value={this.props.data.passwordConfirm} required onChange={this.handleChange} />
                </div>
                <h5 className="mt-4">Note aggiuntive</h5>
                <textarea className="form-control" style={{ minHeight: 100 }} id="notes" onChange={this.handleChange} value={this.props.data.notes} />
                <div className="custom-control custom-checkbox my-2">
                    <input type="checkbox" className="custom-control-input" id="supplierTerms" required />
                    <label className="custom-control-label mt-1" htmlFor="supplierTerms">Accetto <a href={Pdf} target="_blank" rel="noopener noreferrer">i termini e le condizioni</a></label>
                </div>
            </div>
        );
    }
}


export default withRouter(Step4Screen);