import React from 'react'
import { Component } from 'react';
import './styles/Step1Form.css'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DropdownList } from 'react-widgets'
import { withRouter } from 'react-router-dom';

class Step1Form extends Component {
    constructor(props) {
        super(props);
        if (!this.props.data.area)
            this.areaFromProps(props);
    }

    areaFromProps = (props) => {
        if (props.areas) {
            if (props.areas.length > 0)
                this.props.onChange("area", props.areas[0]);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps === this.props)
            return;

        if (!this.props.data.area) {
            this.areaFromProps(this.props);
        }
    }


    render() {
        const { areas } = this.props;

        let body;

        if (!isLoaded(areas)) {
            body = <div>
                Caricamento delle zone disponibili in corso...
            </div>
        }
        else if (isEmpty(areas)) {

            body = <div>
                Nessuna zona disponibile. Ti preghiamo di riprovare più tardi.
            </div>
        }
        else {
            body = (
                <div>
                    <DropdownList data={areas} textField="name" onChange={(area) => this.props.onChange("area", area)} value={this.props.data.area} />
                    <div className="info mt-2">
                        Non riesci a trovare la tua zona? Arriveremo presto!
                    </div>
                </div>
            );
        }
        return (
            <div className="driver-step-1">
                <h3>Seleziona la tua zona</h3>
                {body}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        areas: state.firestore.ordered['areas'],
    }
}


export default withRouter(compose(connect(mapStateToProps), firestoreConnect(() => [{ collection: 'areas' }]))(Step1Form));