import {
  combineReducers
} from 'redux'
import {
  firestoreReducer
} from 'redux-firestore';
import {
  firebaseReducer
} from 'react-redux-firebase';
import {
  reducer as toastr
} from 'react-redux-toastr';
import driverFormReducer from './driverFormReducer';
import supplierFormReducer from './supplierFormReducer';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  toastr: toastr,
  driverForm: driverFormReducer,
  supplierForm: supplierFormReducer,
});

export default rootReducer

// the key name will be the data property on the state object