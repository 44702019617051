import React, { Component } from 'react'
import { DropdownList } from 'react-widgets'

class CategorySelect extends Component {
  constructor(props) {
    super(props);
    this.categoryFromProps(props);
  }

  componentDidUpdate(prevProps) {
    let needsUpdate = prevProps.categories.length !== this.props.categories.length;
    if (!needsUpdate) {
      for (let i = 0; i < prevProps.categories.length; i++) {
        needsUpdate = prevProps.categories[i] !== this.props.categories[i];
        if (needsUpdate)
          break;
      }
    }

    if (needsUpdate) {
      this.categoryFromProps(this.props);
    }
  }

  categoryFromProps = (props) => {
    if (!props.categories)
      return;
    if (!props.category || props.categories.find(c => c.id === props.category.id) === undefined) {
      props.onCategoryChange(props.categories[0]);
    }
  }

  handleCategoryChange = (category) => {
    this.props.onCategoryChange(category);
  }

  render() {
    const { categories } = this.props;
    return (
      <div className="mb-2">
        <div className="form-group mt-3 mb-2">
          <label className="required bmd-label-static">Tipologia</label>
          <DropdownList data={categories} valueField="id" textField="name" onChange={this.handleCategoryChange} value={this.props.category} />
        </div>
        <div className="info">Non trovi la tua categoria? Ci stiamo ancora espandendo!</div>
      </div>
    );
  }

}

export default CategorySelect;