import React from 'react'
import { Component } from 'react';
import './styles/Step1Form.css'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DropdownList } from 'react-widgets'
import { withRouter } from 'react-router-dom';
import BusinessSearchInput from './components/BusinessSearchInput';
import ManualAddressForm from './components/ManualAddressForm';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import firebase from 'firebase/app';
import { getDistance } from 'geolib';

class Step1Form extends Component {
    state = {
        manual: false,
        addressLoading: false,
    }

    constructor(props) {
        super(props);
        this.areaFromProps(props);
    }

    areaFromProps = (props) => {
        if (props.areas) {
            if (props.areas.length > 0)
                this.props.onChange("area", props.areas[0]);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps === this.props)
            return;

        if (!this.props.data.area) {
            this.areaFromProps(this.props);
        }
    }


    toggleManual = () => {
        this.setState({
            manual: !this.state.manual
        });
    }

    handleAddressSelect = async (address) => {
        this.setState({ addressLoading: true });
        try {
            let results = await geocodeByAddress(address);
            if (results.length === 0) {
                this.props.onAddressSelect({ address, coordinates: null, validAddress: false, addressError: "Impossibile determinare le coordinate dell'indirizzo inserito" });
                return;
            }
            let latLng = await getLatLng(results[0]);
            let coordinates = new firebase.firestore.GeoPoint(latLng.lat, latLng.lng);
            let areaCoordinates = this.props.data.area.geohashPoint.geopoint;
            let distance = getDistance({
                latitude: coordinates.latitude, longitude: coordinates.longitude
            }, {
                latitude: areaCoordinates.latitude, longitude: areaCoordinates.longitude
            });
            distance = Math.floor(distance / 1000.0);
            let validAddress = distance <= this.props.data.area.radius;
            let addressError = "";
            if (!validAddress) {
                addressError = "L'indirizzo specificato non si trova nella zona selezionata.";
            }
            let zipCode = results[0].address_components.find((ac) => ac.types.includes("postal_code"))?.long_name;
            if (!zipCode)
                throw -1;
            this.props.onAddressSelect({ address, coordinates, validAddress, addressError, zipCode });
        }
        catch (err) {
            let addressError = "Errore inaspettato!";
            if (err === "ZERO_RESULTS")
                addressError = "Impossibile determinare le coordinate dell'indirizzo inserito";
            this.props.onAddressSelect({ address, coordinates: null, validAddress: false, addressError, zipCode: null });
        }

        this.setState({ addressLoading: false });
    }

    render() {
        const { areas } = this.props;

        let body;

        if (!isLoaded(areas)) {
            body = <div>
                Caricamento delle zone disponibili in corso...
            </div>
        }
        else if (isEmpty(areas)) {

            body = <div>
                Nessuna zona disponibile. Ti preghiamo di riprolete più tardi.
            </div>
        }
        else {
            body = (
                <div>
                    <DropdownList data={areas} valueField="id" textField="name" onChange={(area) => this.props.onChange("area", area)} value={this.props.data.area} />
                    <div className="info mt-2">
                        Non riesci a trovare la tua zona? Arriveremo presto!
                    </div>
                    {
                        this.props.data.area &&
                        <div>
                            <h5 className="mt-3">Indirizzo dell&apos;attività</h5>
                            <div className="mt-2">
                                {
                                    !this.state.manual &&
                                    <BusinessSearchInput partialAddress={this.props.data.partialAddress}
                                        onAddressSelect={this.handleAddressSelect} area={this.props.data.area}
                                        toggleManual={this.toggleManual} />
                                }
                                {
                                    this.state.manual &&
                                    <ManualAddressForm toggleManual={this.toggleManual} onAddressSelect={this.handleAddressSelect} />
                                }
                            </div>
                            <hr></hr>
                            {!this.state.addressLoading && this.props.data.address !== "" &&
                                (
                                    <div className="mt-3 text-grey">
                                        <b>Indirizzo inserito: </b>{this.props.data.address}
                                    </div>
                                )
                            }
                            {!this.state.addressLoading && this.props.data.address !== "" && this.props.data.validAddress &&
                                <div className="address-message address-message-success mt-2 text-success">
                                    <span className="fas fa-check-circle" /> L&apos;indirizzo inserito è valido e si trova all&apos;interno della zona selezionata
                                </div>
                            }
                            {!this.state.addressLoading && this.props.data.address !== "" && !this.props.data.validAddress &&
                                <div className="address-message address-message-error mt-2 text-warning">
                                    <span className="fas fa-exclamation-circle" /> {this.props.data.addressError}
                                </div>
                            }
                            {this.state.addressLoading &&
                                <div className="d-flex align-items-center">
                                    <div className="small-loader"></div>
                                    <div>Controllo dell&apos;indirizzo in corso...</div>
                                </div>
                            }
                        </div>
                    }
                </div>
            );
        }
        return (
            <div className="supplier-step-1">
                <h3>Modulo di richiesta - Step 1/4</h3>
                <h5>Seleziona la tua zona</h5>
                {body}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        areas: state.firestore.ordered['areas'],
    }
}


export default withRouter(compose(connect(mapStateToProps), firestoreConnect(() => [{ collection: 'areas' }]))(Step1Form));