import { toastr } from "react-redux-toastr";
import { resizeImage } from "../../utility/FileHelper";
import RequestStates from "../../utility/RequestStates";
import moment from "moment";

export const createSupplierRequest = (data) => {
    return async (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SUPPLIER_REQUEST_LOADING' });
        const firebase = getFirebase();

        const firestore = firebase.firestore();
        const storage = firebase.storage();
        const auth = firebase.auth();

        const documentRef = firestore.collection('supplier_requests').doc();

        const storageRef = storage.ref().child('supplier_requests/' + documentRef.id);

        try {
            let uid = (await auth.createUserWithEmailAndPassword(data.email, data.password)).user.uid;

            let fullSizeImage = await resizeImage(data.imageFile, 1200, 800, 100);
            let thumbnailImage = await resizeImage(data.imageFile, 600, 400, 90);

            await storageRef.child("full_size_image").put(fullSizeImage);
            await storageRef.child("thumbnail_image").put(thumbnailImage);
            let fullSizeImageUrl = await storageRef.child("full_size_image").getDownloadURL();
            let thumbnailImageUrl = await storageRef.child("thumbnail_image").getDownloadURL();

            const tags = data.tags.map((t) => t.id);

            await documentRef.set({
                name: data.name,
                email: data.email,
                uid: uid,
                partitaIVA: data.partitaIVA,
                companyName: data.companyName,
                supplierName: data.supplierName,
                description: data.description,
                imageUrl: fullSizeImageUrl,
                thumbnailUrl: thumbnailImageUrl,
                phoneNumber: data.phoneNumber,
                address: data.address,
                coordinates: data.coordinates,
                state: RequestStates.PENDING,
                category: data.category.id,
                ipa: data.ipa,
                registeredOfficeAddress: data.registeredOfficeAddress,
                tags: tags,
                iban: data.iban,
                areaId: data.area.id,
                zip: data.zipCode,
                timestamp: firebase.firestore.Timestamp.fromMillis(moment().valueOf()),
                reference: documentRef,
                adminPhoneNumber: data.adminPhoneNumber,
                notes: data.notes,
                canUseOwnDrivers: data.canUseOwnDrivers,
                ...data.canUseOwnDrivers && {
                    supplierPercentageWithOwnDrivers: data.supplierPercentageWithOwnDrivers,
                    deliveryAmountWithOwnDrivers: data.deliveryAmountWithOwnDrivers
                },
                supplierPercentage: data.supplierPercentage,
                hasPercentagePromo: data.hasPercentagePromo,
                ...data.hasPercentagePromo && {
                    promoEndDate: data.promoEndDate,
                    promoSupplierPercentage: data.promoSupplierPercentage
                },
            });

            dispatch({ type: 'SUPPLIER_REQUEST_SUCCESS' });
        }
        catch (err) {
            if (err.code === 'auth/email-already-in-use') {
                toastr.error('Email già in uso!');
                dispatch({ type: 'SUPPLIER_REQUEST_ERROR', step: 3 })
            } else if (err.code === 'auth/invalid-email') {
                toastr.error("Formato dell'email non valido!");
                dispatch({ type: 'SUPPLIER_REQUEST_ERROR', step: 3 })
            } else if (err.code === 'auth/weak-password') {
                toastr.error("Password troppo corta!");
                dispatch({ type: 'SUPPLIER_REQUEST_ERROR', step: 3 })
            } else {
                toastr.error(`Errore inaspettato!`);
                dispatch({ type: 'SUPPLIER_REQUEST_ERROR', step: 0 })
            }
            console.log(err);
        }
    }
};