import React from 'react'
import { Component } from 'react';
import './styles/ConfirmView.css';
import Container from '../../../common/container/Container';

class ConfirmView extends Component {
    render() {
        return (
            <div className="supplier-confirm">
                <Container>
                    <h3>Richiesta inviata!</h3>
                    La tua richiesta è andata a buon fine e verrà elaborata nei prossimi giorni. <br />
                    Riceverai una email contente l&apos;esito e ulteriori informazioni per proseguire.
              </Container>
            </div >
        );
    }
}


export default ConfirmView;