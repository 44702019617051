import React from 'react'
import { Component } from 'react';
import './styles/Step2Form.css'
import SingleImageUploader from '../../../common/SingleImageUploader';

class Step2Form extends Component {
    handleIDCardFrontImageChange = (imageFile) => {
        this.props.onChange("IDCardFrontFile", imageFile);
    }

    handleIDCardFrontPreviewLinkChange = (imagePreviewLink) => {
        this.props.onChange("IDCardFrontPreviewLink", imagePreviewLink);
    }

    handleIDCardBackImageChange = (imageFile) => {
        this.props.onChange("IDCardBackFile", imageFile);
    }

    handleIDCardBackPreviewLinkChange = (imagePreviewLink) => {
        this.props.onChange("IDCardBackPreviewLink", imagePreviewLink);
    }

    handleImageChange = (imageFile) => {
        this.props.onChange("imageFile", imageFile);
    }

    handleImagePreviewLinkChange = (imagePreviewLink) => {
        this.props.onChange("imagePreviewLink", imagePreviewLink);
    }

    handleChange = (e) => {
        this.props.onChange(e.target.id, e.target.value);
    }

    render() {
        return (
            <div className="driver-step-2">
                <h3>Modulo di richiesta - Step 1/2</h3>
                <h5 className="mt-2">Informazioni personali</h5>
                <div className="form-group">
                    <label htmlFor="name" className="required">Nome e cognome</label>
                    <input className="form-control" type="text" id="name" value={this.props.data.name} required onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="fiscalCode" className="required">Codice fiscale</label>
                    <input className="form-control" type="text" id="fiscalCode" value={this.props.data.fiscalCode} required onChange={this.handleChange} />
                </div>
                <SingleImageUploader required={true} title="Foto documento d'identità (fronte)"
                    onImageChange={this.handleIDCardFrontImageChange} onImagePreviewLinkChange={this.handleIDCardFrontPreviewLinkChange}
                    imagePreviewLink={this.props.data.IDCardFrontPreviewLink} imageFile={this.props.data.IDCardFrontFile} />
                <div className="my-3" />
                <SingleImageUploader required={true} title="Foto documento d'identità (retro)"
                    onImageChange={this.handleIDCardBackImageChange} onImagePreviewLinkChange={this.handleIDCardBackPreviewLinkChange}
                    imagePreviewLink={this.props.data.IDCardBackPreviewLink} imageFile={this.props.data.IDCardBackFile} />
                <div className="my-3" />
                <SingleImageUploader required={true} title="Immagine di profilo (si deve vedere chiaramente il viso)"
                    onImageChange={this.handleImageChange} onImagePreviewLinkChange={this.handleImagePreviewLinkChange}
                    imagePreviewLink={this.props.data.imagePreviewLink} imageFile={this.props.data.imageFile} />
                <div className="form-group mt-2">
                    <label htmlFor="description" className="required">Descrizione personale (eventuali esperienze, CV, ecc.)</label>
                    <textarea className="form-control" style={{ minHeight: 200 }} id="description" onChange={this.handleChange} value={this.props.data.description} required />
                </div>
                <div className="form-group">
                    <label htmlFor="meansOfTransport" className="required">Mezzi di traporto a disposizione</label>
                    <textarea className="form-control" style={{ minHeight: 200 }} id="meansOfTransport" onChange={this.handleChange} value={this.props.data.meansOfTransport} required />
                </div>
            </div>
        );
    }
}

export default Step2Form;