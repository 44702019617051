import React from 'react';
import './styles/Container.css';

export default function Container(params) {
    return (
        <div className="my-container">
            <div className="line-container">
                <div className="line" />
            </div>
            <div className="my-container-body py-4 px-4">
                {params.children}
            </div>
        </div>
    );
}