import React from 'react'
import { Component } from 'react';
import './styles/Step2Form.css'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import CategorySelect from './components/CategorySelect';

class Step2Form extends Component {
    handleChange = (e) => {
        this.props.onChange(e.target.id, e.target.value);
    }


    handleCategoryChange = (category) => {
        this.props.onChange("category", category);
        this.props.onChange("tags", []);
    }


    render() {
        let { categories } = this.props;
        return (
            <div className="supplier-step-2">
                <h3>Modulo di richiesta - Step 2/4</h3>
                <h5 className="mt-4">Informazioni dell&apos;attività</h5>
                {isLoaded(categories) && <CategorySelect categories={categories} onCategoryChange={this.handleCategoryChange} category={this.props.data.category} />}
                <div className="form-group">
                    <label htmlFor="companyName" className="required">Ragione sociale</label>
                    <input className="form-control" type="text" id="companyName" value={this.props.data.companyName} required onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="supplierName" className="required">Nome dell&apos;attività</label>
                    <input className="form-control" type="text" id="supplierName" value={this.props.data.supplierName} required onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="registeredOfficeAddress">Indirizzo sede legale (se diversa dall&apos;indirizzo inserito in precedenza)</label>
                    <input className="form-control" type="text" id="registeredOfficeAddress" value={this.props.data.registeredOfficeAddress} onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="partitaIVA" className="required">Partita IVA</label>
                    <input className="form-control" type="text" id="partitaIVA" value={this.props.data.partitaIVA} required onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="required">Numero di telefono</label>
                    <input className="form-control" type="text" id="phoneNumber" value={this.props.data.phoneNumber} required onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="name" className="required">Nome e cognome del titolare</label>
                    <input className="form-control" type="text" id="name" value={this.props.data.name} required onChange={this.handleChange} />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        categories: state.firestore.ordered['categories'],
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        return [
            {
                collection: 'supplier_categories',
                storeAs: 'categories',
            },
        ];
    }),
)(Step2Form);