/*global google*/

import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import './styles/BusinessSearchInput.css'

class BusinessSearchInput extends React.Component {
    state = {
        partialAddress: "",
    }

    handleChange = address => {
        this.setState({ partialAddress: address });
    };

    handleSelect = async (address) => {
        this.setState({ partialAddress: "" });
        this.props.onAddressSelect(address);
    };

    render() {
        const { area } = this.props;

        const searchOptions = {
            location: new google.maps.LatLng(area.geohashPoint.geopoint.latitude, area.geohashPoint.geopoint.longitude),
            radius: area.radius * 1000,
            types: ['establishment']
        }
        return (
            <div>
                <label>Ricerca dell&apos;attività</label>
                <PlacesAutocomplete
                    value={this.state.partialAddress}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={searchOptions}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (

                        <div className="business-search">
                            <div className="search-input-container">

                                <input
                                    {...getInputProps({
                                        placeholder: 'Cerca la tua attività...',
                                        className: 'form-control',
                                    })}
                                />
                            </div>
                            {suggestions.length > 0 && (
                                <div className="autocomplete-container">
                                    {suggestions.map(suggestion => {
                                        const className = 'suggestion-item' + (suggestion.active ?
                                            ' suggestion-item--active' : '');
                                        /* eslint-disable react/jsx-key */
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, { className })}
                                            >
                                                <strong>
                                                    {suggestion.formattedSuggestion.mainText}
                                                </strong>{' '}
                                                <small>
                                                    {suggestion.formattedSuggestion.secondaryText}
                                                </small>
                                            </div>
                                        );
                                        /* eslint-enable react/jsx-key */
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </PlacesAutocomplete>

                <div className="mt-2">
                    <span className="manual-info">Non trovi la tua attività?</span>
                    <span className="manual" onClick={this.props.toggleManual}> Inserisci l&apos;indirizzo manualmente</span>
                </div>

            </div>
        );
    }
}

export default BusinessSearchInput;