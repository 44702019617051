
import Resizer from 'react-image-file-resizer';

const resizeImage = (inputFile, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      inputFile,
      maxWidth,
      maxHeight,
      'JPEG',
      quality,
      0,
      blob => {
        resolve(blob)
      },
      'blob'
    );
  });
};

export { resizeImage };