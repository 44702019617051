import React, { Component } from 'react'

class SingleImageUploader extends Component {

  handleImageSelect = (imageFile) => {
    if (imageFile !== undefined) {
      this.props.onImageChange(imageFile);

      let reader = new FileReader();
      reader.onload = (e) => {
        this.props.onImagePreviewLinkChange(e.target.result);
      }
      reader.readAsDataURL(imageFile);
    }
  }

  handleImageDelete = () => {
    this.props.onImageChange(null);
    this.props.onImagePreviewLinkChange("");
  }

  render() {
    const { required } = this.props;
    return (
      <div>
        <label className={"bmd-label-static " + (required === true ? "required" : "")} htmlFor="imageSelect">{this.props.title}</label>
        <div id="imagePreview" className={"imagePreview position-relative my-2 " + (this.props.imagePreviewLink !== "" ? "d-block" : "d-none")}>
          <div className="deleteImage" onClick={this.handleImageDelete}>
            <i aria-hidden="true" className="fa fa-times" />
          </div>
          <img src={this.props.imagePreviewLink} style={{
            maxHeight: 300, maxWidth: "100%",
            boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)"
          }} />
        </div>
        <br></br>
        <button className="btn btn-outline-secondary" type="button" onClick={() => this.inputElement.click()}>Seleziona immagine</button>
        <input type="file" className="d-none" id="imageSelect" lang="it" ref={input => this.inputElement = input}
          onChange={(e) => this.handleImageSelect(e.target.files[0])} accept="image/png, image/jpeg"
          onClick={(event) => { event.target.value = null }} />

      </div>
    );
  }

}

export default SingleImageUploader;