import React from 'react'
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './styles/LoadingView.css';

class LoadingView extends Component {
    render() {
        return (
            <div className="driver-loading">
                <div className="loading-container">
                    <div className="loader mb-2" />
                    <div className="text-center">Invio della richiesta in corso...</div>
                </div>
            </div>
        );
    }
}

export default withRouter(LoadingView);