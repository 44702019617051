import React, { Component } from 'react'
import { Multiselect } from 'react-widgets'

class TagSelect extends Component {
  constructor(props) {
    super(props);
    this.tagsFromProps(props);
  }

  componentDidUpdate(prevProps) {
    let needsUpdate = prevProps.tags.length !== this.props.tags.length;
    if (!needsUpdate) {
      for (let i = 0; i < prevProps.tags.length; i++) {
        needsUpdate = prevProps.tags[i] !== this.props.tags[i];
        if (needsUpdate)
          break;
      }
    }

    if (needsUpdate) {
      this.tagsFromProps(this.props);
    }
  }

  tagsFromProps(props) {
    if (props.selectedTags && props.selectedTags.length > 0)
      return;
    this.props.onTagListChange([]);
  }

  handleTagListChange = (tags) => {
    this.props.onTagListChange(tags);
  }

  render() {
    const { tags } = this.props;
    let disabled = [];
    if (this.props.selectedTags.length > 2)
      disabled = tags.filter((t1) => (this.props.selectedTags.findIndex((t2) => t1.id === t2.id) === -1));

    return (
      <div className="form-group mt-3">
        <label className="required bmd-label-static">Tag (fino a 3)</label>
        <Multiselect
          valueField='id'
          textField='name'
          data={tags}
          value={this.props.selectedTags}
          onChange={this.handleTagListChange}
          disabled={disabled}
          messages={{
            emptyList: 'Non ci sono altri tag disponibili',
            emptyFilter: 'Nessun tag disponibile',
          }}
        />
      </div>
    );
  }

}

export default TagSelect;