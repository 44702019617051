import React from 'react'
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import './styles/Navbar.css'
import logo from './images/logoNavbar.png'

const MyNavbar = () => {
  return (
    <Navbar bg="light" variant="light" className="py-0 my-navbar">
      <Navbar.Brand as={Link} to="/" className="py-0 mx-md-5">
        <img src={logo} className="mr-2 ml-md-5 mt-1" style={{ maxHeight: 50 }} />
        <span className="my-auto mr-md-5 title">Taporty</span>
      </Navbar.Brand>
    </Navbar>
  );
}

export default MyNavbar;

