import React from 'react'
import { Component } from 'react';
import './styles/Step3Form.css'
import { withRouter } from 'react-router-dom';
import SingleImageUploader from '../../../common/SingleImageUploader';
import TagSelect from './components/TagSelect'
import NumberFormat from 'react-number-format';

class Step3Screen extends Component {
    handleImageChange = (imageFile) => {
        this.props.onChange("imageFile", imageFile);
    }

    handleImagePreviewLinkChange = (imagePreviewLink) => {
        this.props.onChange("imagePreviewLink", imagePreviewLink);
    }

    handleChange = (e) => {
        this.props.onChange(e.target.id, e.target.value);
    }

    render() {
        return (
            <div className="supplier-step-3">
                <h5 className="mt-4">Scheda dell&apos;attività nell&apos;app</h5>
                <div className="form-group">
                    <label htmlFor="description" className="required">Descrizione breve</label>
                    <textarea className="form-control" style={{ minHeight: 100 }} id="description" onChange={this.handleChange} value={this.props.data.description} required />
                </div>
                <SingleImageUploader required={true} title="Immagine di profilo)"
                    onImageChange={this.handleImageChange} onImagePreviewLinkChange={this.handleImagePreviewLinkChange}
                    imagePreviewLink={this.props.data.imagePreviewLink} imageFile={this.props.data.imageFile} />
                {
                    this.props.data.category !== null &&
                    <TagSelect tags={this.props.data.category.tags} onTagListChange={(tags) => this.props.onChange("tags", tags)} selectedTags={this.props.data.tags} />
                }

                <h5 className="mt-4">Percentuale fornitore</h5>
                <div className="form-group mt-2">
                    <label className="required">Percentuale fornitore: </label>
                    <NumberFormat
                        decimalScale={2}
                        allowNegative={false}
                        thousandSeparator={false}
                        decimalSeparator=","
                        suffix="%"
                        fixedDecimalScale={true}
                        className="form-control"
                        value={this.props.data.supplierPercentage === undefined ? undefined : this.props.data.supplierPercentage * 100.}
                        isAllowed={(values) => {
                            const { floatValue } = values;
                            if (floatValue === undefined)
                                return true;
                            return floatValue > 0 && floatValue <= 100;
                        }}
                        onValueChange={({ floatValue }) => {
                            if (floatValue !== this.props.data.supplierPercentage)
                                this.props.onChange("supplierPercentage", floatValue === undefined ? undefined : floatValue / 100.)
                        }} required />
                </div>
                <div className="custom-control custom-checkbox my-2">
                    <input type="checkbox" className="custom-control-input" id="hasPercentagePromo" onChange={(e) => this.props.onChange("hasPercentagePromo", e.target.checked)} />
                    <label className="custom-control-label mt-1" htmlFor="hasPercentagePromo">Ho una promozione sulla percentuale a me dovuta</label>
                </div>
                {
                    this.props.data.hasPercentagePromo && <div className="ml-4">
                        <div className="form-group mt-2">
                            <label className="required">Percentuale fornitore durante la promozione: </label>
                            <NumberFormat
                                decimalScale={2}
                                allowNegative={false}
                                thousandSeparator={false}
                                decimalSeparator=","
                                suffix="%"
                                fixedDecimalScale={true}
                                className="form-control"
                                value={this.props.data.promoSupplierPercentage === undefined ? undefined : this.props.data.promoSupplierPercentage * 100.}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    if (floatValue === undefined)
                                        return true;
                                    return floatValue > 0 && floatValue <= 100;
                                }}
                                onValueChange={({ floatValue }) => {
                                    if (floatValue !== this.props.data.promoSupplierPercentage)
                                        this.props.onChange("promoSupplierPercentage", floatValue === undefined ? undefined : floatValue / 100.)
                                }} required />
                        </div>
                        <div className="form-group mt-2">
                            <label className="required">Data di fine della promozione: </label>
                            <input type="date" id="promoEndDate" className="form-control" value={this.props.data.promoEndDate} onChange={this.handleChange} required />
                        </div>
                    </div>
                }

                <h5 className="mt-4">Utilizzo propri fattorini</h5>
                <div className="custom-control custom-checkbox my-2">
                    <input type="checkbox" className="custom-control-input" id="canUseOwnDrivers" onChange={(e) => this.props.onChange("canUseOwnDrivers", e.target.checked)} />
                    <label className="custom-control-label mt-1" htmlFor="canUseOwnDrivers">Ho a disposizione dei fattorini da assegnare agli ordini</label>
                </div>
                {
                    this.props.data.canUseOwnDrivers &&
                    <div className="ml-4">
                        <div className="form-group mt-2">
                            <label className="required">Percentuale fornitore con fattorini propri: </label>
                            <NumberFormat
                                decimalScale={2}
                                allowNegative={false}
                                thousandSeparator={false}
                                decimalSeparator=","
                                suffix="%"
                                fixedDecimalScale={true}
                                className="form-control"
                                value={this.props.data.supplierPercentageWithOwnDrivers === undefined ? undefined : this.props.data.supplierPercentageWithOwnDrivers * 100.}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    if (floatValue === undefined)
                                        return true;
                                    return floatValue > 0 && floatValue <= 100;
                                }}
                                onValueChange={({ floatValue }) => {
                                    if (floatValue !== this.props.data.supplierPercentageWithOwnDrivers)
                                        this.props.onChange("supplierPercentageWithOwnDrivers", floatValue === undefined ? undefined : floatValue / 100.)
                                }} required />
                        </div>
                        <div className="form-group mt-2">
                            <label className="required">Costo spedizione con fattorini propri: </label>
                            <NumberFormat
                                decimalScale={2}
                                allowNegative={false}
                                thousandSeparator={false}
                                decimalSeparator=","
                                suffix="€"
                                fixedDecimalScale={true}
                                className="form-control"
                                value={this.props.data.deliveryAmountWithOwnDrivers}
                                onValueChange={({ floatValue }) => {
                                    if (floatValue !== this.props.data.deliveryAmountWithOwnDrivers)
                                        this.props.onChange("deliveryAmountWithOwnDrivers", floatValue)
                                }} required />
                        </div>
                    </div>
                }
            </div>
        );
    }
}


export default withRouter(Step3Screen);