import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'react-widgets/dist/css/react-widgets.css';
import MainScreen from './components/main/MainScreen'
import MyNavbar from './components/common/navbar/Navbar'
import DriverFormScreen from './components/driver/DriverFormScreen';
import SupplierFormScreen from './components/supplier/SupplierFormScreen';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route path='/' exact>
              <MyNavbar />
              <MainScreen />
            </Route>
            <Route path='/driver'>
              <MyNavbar />
              <DriverFormScreen />
            </Route>
            <Route path='/supplier'>
              <MyNavbar />
              <SupplierFormScreen />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
