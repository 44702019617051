import React from 'react'
import { Component } from 'react';
import Container from '../common/container/Container';
import { connect } from 'react-redux';
import { createDriverRequest } from '../../store/actions/driverFormActions'
import { withRouter } from 'react-router-dom';
import Step1Form from './components/step1/Step1Form';
import Step2Form from './components/step2/Step2Form';
import Step3Form from './components/step3/Step3Form';
import LoadingView from './components/loading/LoadingView';
import ConfirmView from './components/confirm/ConfirmView';
import './styles/DriverFormScreen.css';

class DriverFormScreen extends Component {
    state = {
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
        fiscalCode: "",
        description: "",
        imageFile: null,
        imagePreviewLink: "",
        IDCardFrontFile: null,
        IDCardFrontPreviewLink: "",
        IDCardBackFile: null,
        IDCardBackPreviewLink: "",
        phoneNumber: "",
        meansOfTransport: "",
        iban: "",
        area: null,
        step: 0,
        error: "",
        requestSent: false,
    }

    onBackButtonClick = () => {
        if (this.state.step > 0) {
            this.setState({ step: this.state.step - 1 });
        }
        else {
            this.props.history.push("/");
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.step === 1) {
            if (!this.state.imageFile) {
                this.setState({
                    error: "Immagine di profilo non selezionata."
                });
                return;
            }
            if (!this.state.IDCardFrontFile || !this.state.IDCardBackFile) {
                this.setState({
                    error: "Foto del documento d'identità non inserite."
                });
                return;
            }
        }
        else if (this.state.step === 2) {
            if (this.state.password !== this.state.passwordConfirm) {
                this.setState({
                    error: "Le password inserite non corrispondono."
                });
                return;
            }
            const iban = this.state.iban.replace(/\s/g, '');
            if (!(/^([a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16})$/.test(iban))) {
                this.setState({
                    error: "IBAN inserito non valido."
                });
                return;
            }

            this.props.createDriverRequest({ ...this.state, iban });
            this.setState({ requestSent: true });
        }

        this.setState({ step: this.state.step + 1, error: "" });
    }

    handleChange = (id, value) => {
        this.setState({
            [id]: value,
        });
    }

    componentDidUpdate() {
        if (this.state.requestSent === true && this.props.requestLoading === false && this.props.requestError === true) {
            this.setState({ step: this.props.stepWithError, requestSent: false });
        }
        if (this.props.requestLoading && this.state.requestSent === false) {
            this.setState({ requestSent: true });
        }
    }


    render() {
        var step1Form = <Step1Form onChange={this.handleChange} data={this.state} />;
        var step2Form = <Step2Form onChange={this.handleChange} data={this.state} />;
        var step3Form = <Step3Form onChange={this.handleChange} data={this.state} />;
        let body;

        if (this.state.requestSent) {
            if (this.props.requestLoading) {
                body = <LoadingView />;
            }
            else if (this.props.requestSuccess) {
                body = <ConfirmView />;
            }
        }

        else {
            body = <Container>
                <form onSubmit={this.handleSubmit} className="driverForm">
                    {
                        this.state.step === 0 && step1Form
                    }
                    {
                        this.state.step === 1 && step2Form
                    }
                    {
                        this.state.step === 2 && step3Form
                    }
                    <div className="text-danger">
                        {this.state.error !== "" ? <p>{this.state.error}</p> : null}
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-12 mt-3 back-container">
                            <button className="btn btn-outline-primary button" type="button" onClick={this.onBackButtonClick}>Indietro</button>
                        </div>
                        <div className="col-lg-9 col-md-8 col-12 mt-3 next-container">
                            <button className="btn btn-primary button">{this.state.step === 2 ? "Invia" : "Prosegui"}</button>
                        </div>
                    </div>
                </form>
            </Container>
        }

        return (
            <div className="driver">
                {body}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        requestLoading: state.driverForm.requestLoading,
        requestSuccess: state.driverForm.requestSuccess,
        requestError: state.driverForm.requestError,
        stepWithError: state.driverForm.stepWithError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createDriverRequest: (data) => dispatch(createDriverRequest(data)),
    }
}





export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DriverFormScreen));